import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Button } from 'components/ui/button'
import { CookingPot, Facebook, Instagram, Linkedin } from 'lucide-react'
const BLOG_URL = process.env.REACT_APP_BLOG_URL;

const DropDownMenu: React.FC = () => {
  const [post, setPost] = useState(Object)

  useEffect(() => {
    const getById = () => {
      const url = `${BLOG_URL}/posts/482`;
      return axios
        .get(url)
        .then(response => setPost(response.data))
        .catch(error => console.log(error));
    }
    getById()
  }, [])

  console.log(post)

  return (
    <div className='p-4 flex space-x-4'>
      <div className='w-72 bg-secondary p-4 rounded-md flex flex-col'>
        <CookingPot />
        <h3 className='text-lg font-bold'>Nos idées recettes</h3>
        <div className='flex-1 flex flex-col space-y-3 my-2'>
          <a href={post.link} className='h-full relative'>
            <div className='absolute w-full h-full bg-black opacity-30 top-0 rounded-md'></div>
            <div 
              className='rounded-md h-full bg-cover bg-no-repeat p-2 flex items-center' 
              style={{backgroundImage: `url(${post.yoast_head_json?.og_image[0].url})`}}
            >
              <h5 className='text-white font-bold z-50'>{post.title?.rendered}</h5>
            </div>
          </a>
        </div>
        <Button href='https://www.mullo.fr/blog/categories/34/recette' variant="outline-seconday" className='w-full' size="sm">
          en voir plus
        </Button>
      </div>
      <div className='w-72'>
        <ul className='space-y-2'>
          <ListItem href="" title="Tous nos regroupements">
            Nos producteurs d'île-de-France se regroupent.
          </ListItem>
          <ListItem href="https://forms.fillout.com/t/v9auhK6gFYus?type=websiteContact" title="Contactez nous">
            Prenez contact avec nous par mail.
          </ListItem>
          <li className='flex space-x-2 p-3'>
            <a 
              href="https://www.instagram.com/mullo.fr/" 
              className='bg-neutral-300 hover:bg-neutral-400 w-10 h-10 rounded-full flex justify-center items-center'
            >
              <Instagram color='white' />
            </a>

            <a 
              href="https://www.facebook.com/profile.php?id=61560858286369" 
              className='bg-neutral-300 hover:bg-neutral-400 w-10 h-10 rounded-full flex justify-center items-center'
            >
              <Facebook color='white' />
            </a>

            <a 
              href="https://www.linkedin.com/company/mullo-france" 
              className='bg-neutral-300 hover:bg-neutral-400 w-10 h-10 rounded-full flex justify-center items-center'
            >
              <Linkedin color='white' />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default DropDownMenu


const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <a
        ref={ref}
        className="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-neutral-100"
        {...props}
      >
        <div className="text-sm font-medium leading-none">{title}</div>
        <p className="line-clamp-2 text-sm leading-snug text-muted-foreground mt-1">
          {children}
        </p>
      </a>
    </li>
  )
})
ListItem.displayName = "ListItem"