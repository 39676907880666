let setCategoryState: ((state: { category: string }) => void) | null = null;
let currentCategory: string | null = null;

export const switchCategory = (category: string) => {
    currentCategory = category;
    if (setCategoryState) {
        setCategoryState({ category }); 
    }
};

export const registerCategoryService = (setStateFunction: (state: { category: string }) => void) => {
    setCategoryState = setStateFunction;
};

export const getCategorySelected = (): string | null => {
    return currentCategory;
};