import React, { useState, SetStateAction, Dispatch } from 'react';
// import DeliveryOptionCard from 'components/Card/DeliveryOptionCard';
import logo from 'assets/logo.png';
// import { getNextDay, getMonthFromTimestamp, getYearFromTimestamp } from 'api/DateService';
// import { DateModel } from 'models/DateModel';
import { Button } from 'components/ui/button';
import { useCart } from 'services/CartService';
import { useUser } from '@clerk/clerk-react';
import useOrdersService from 'api/orders/OrdersServices';
// import moment from 'moment';

interface DeliveryInfosCardProps {
  setOrderDone: Dispatch<SetStateAction<string>>;
  setMessage: Dispatch<SetStateAction<string>>;
  orderComplete: boolean;
}

const DeliveryInfosCard: React.FC<DeliveryInfosCardProps> = ({ setOrderDone, setMessage, orderComplete }) => {
  // const [sortedDays, setSortedDays] = useState<DateModel[]>([]);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [deliveryDate, setDeliveryDate] = useState<string | null>(null);
  const [isChecked, setIsChecked] = useState(false);
  const { getCart, clearCart } = useCart();
  const { user } = useUser();
  const { postNewOrder } = useOrdersService();

  const postOrder = async () => {
    const cartItems = getCart().filter(product => product.selected === true);
    const order = {
      companyId: user?.publicMetadata?.crmCompanyId,
      wishDeliveryDate: deliveryDate, //moment(deliveryDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      products: cartItems.map((item) => ({
        id: item.productId,
        quantity: item.quantity
      }))
    }
    const response = await postNewOrder(order)
    if (response.hasError) {
      setMessage("Une erreur est survenue ! Si le problème persiste contactez notre support : contact@mullo.fr")
    } else {
      clearCart()
      setOrderDone(response.response.expectedDeliveryDate)
    }
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // useEffect(() => {
  //   const getDates = () => {
  //     const firstNearDate = getNextDay({ day: "jeudi" });
  //     const secondNearDate = getNextDay({ day: "vendredi" });

  //     const dates = [firstNearDate, secondNearDate];

  //     const sortedDates = dates.sort((a, b) => {
  //       const today = new Date();
  //       const dateA = new Date(today.getFullYear(), today.getMonth(), parseInt(a.number));
  //       const dateB = new Date(today.getFullYear(), today.getMonth(), parseInt(b.number));

  //       return dateA.getTime() - dateB.getTime();
  //     });

  //     setSortedDays(sortedDates);
  //   };

  //   getDates();
  // }, []);

  // const optionsDelivery = sortedDays.map((dateInfo, index) => ({
  //   icon: logo,
  //   deliveryOptionName: 'Livraison au pas de porte',
  //   deliveryDate: `${dateInfo.day} ${dateInfo.number}/${getMonthFromTimestamp(dateInfo.timestamp)}/${getYearFromTimestamp(dateInfo.timestamp)}`
  // }));
  const optionsDelivery = [
    {
      icon: logo,
      deliveryOptionName: 'Livraison au pas de porte',
      deliveryDate: '2024-10-30'
    },
    {
      icon: logo,
      deliveryOptionName: 'Livraison au pas de porte',
      deliveryDate: '2024-11-07'
    }
  ]

  const handleOptionClick = (index: number, deliveryDate: string) => {
    setSelectedOption(index);
    setDeliveryDate(deliveryDate);
  };

  return (
    <div className="bg-white rounded-lg border border-gray-400 p-6 shadow-sm">
      {/* <h2 className="text-lg font-bold mb-2">Adresse de livraison</h2>
      <div className="flex space-x-4">
        L'adresse de facturation sera précisée sur la facture émise lors de la validation de la commande.
      </div> */}
      <h2 className="text-lg font-bold mb-2">Méthode de livraison</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {optionsDelivery.map((option, index) => (
          <div
            key={index}
            className={`p-2 rounded-lg cursor-pointer transition-all duration-300 flex ${selectedOption === index ? 'bg-indigo-50' : 'bg-white hover:bg-indigo-50'
              }`}
            onClick={() => handleOptionClick(index, option.deliveryDate)}
          >
            <div className="w-2/5 items-center justify-center hidden sm:flex">
              <img
                className="h-auto w-full"
                src="https://rozho-blog-yby1k94dz0.live-website.com/wp-content/uploads/2024/09/delivery.jpg"
                alt=""
                style={{
                  maxHeight: '100%',
                }}
              />
            </div>

            <div className="w-3/5 pl-4">
              <h3 className="font-bold">{option.deliveryOptionName}</h3>
              <p>Livraison : {option.deliveryDate}</p>
            </div>
          </div>
        ))}
      </div>
      <p className="mt-2 text-sm mb-5">
        Date de livraison estimée : {deliveryDate}
      </p>
      <hr />
      <div className="flex items-start my-4">
        <input
          type="checkbox"
          id="acceptPayment"
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="mr-2 mt-1"
        />
        <label htmlFor="acceptPayment" className="text-sm text-gray-700 cursor-pointer">
          En cochant cette case vous acceptez les conditions générales de vente et vous vous engagez à payer la facture sous les 30 jours suivant l'achat.
        </label>
      </div>
      <Button onClick={() => postOrder()} disabled={!isChecked || !deliveryDate || !orderComplete} className="w-full">
        Valider la commande
      </Button>
    </div>
  );
};

export default DeliveryInfosCard;