import React, { useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useParams, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { CartProvider } from '../services/CartService';
import Footer from 'components/Globals/Footer';

const Home: React.FC = () => {
  const { isSignedIn, user, isLoaded } = useUser();
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoaded && location.pathname === '/') {
      if (user?.publicMetadata.userType === 'producer') {
        navigate(`/dashboard/commandes`);
      } else if (user?.publicMetadata.userType === 'buyer') {
        //navigate(`/dashboard/commandes`);
        navigate(`/boutique/${organizationId || 'org_2mA6fQMQobyFQFjByLri9x3tTi0'}`);
      } else {
        navigate(`/boutique/${organizationId || 'org_2mA6fQMQobyFQFjByLri9x3tTi0'}`)
      }
    }
  }, [isLoaded, isSignedIn, user, organizationId, navigate, location.pathname]);

  // Don't render anything until the user is fully loaded
  if (!isLoaded) {
    return null;
  }

  return (
    <CartProvider>
      <div style={{ backgroundColor: "#F9F9F9" }}>
        <Outlet />
        <Footer />
      </div>
    </CartProvider>
  );
};
export default Home;
