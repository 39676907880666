import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { AddressModel } from 'models/AddressModel';
import useAddressesService from 'api/addresses/addressesService';
import { useNavigate } from 'react-router-dom';

const NoAddressBanner: React.FC = () => {
    const { user } = useUser();
    const [addresses, setAddresses] = useState<AddressModel[]>([]);
    const navigate = useNavigate();
    const { getUserAddresses } = useAddressesService();

    useEffect(() => {
        const getAddresses = async () => {
            if (user?.id) {
                const userAddresses = await getUserAddresses(user.id);
                setAddresses(userAddresses);
            }
        };
        getAddresses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id]);

    const handleNavigate = () => {
        navigate('/dashboard/adresse');
    }

    return (
        <>
            {addresses.length === 0 && (
                <div className="w-full bg-orange-500 text-white text-center py-2 z-900" onClick={handleNavigate}>
                    <button className="w-full text-white bg-transparent border-none cursor-pointer">
                        Pour finaliser votre compte : Renseignez votre adresse par ici
                    </button>
                </div>
            )}
        </>
    );
};


export default NoAddressBanner;
