import NavBar from 'components/Globals/Navs/NavBar';
import React from 'react';
import { Outlet } from 'react-router-dom';

const MarketplaceLayout: React.FC = () => {

  return (
    <div className="">
      <NavBar />
      <Outlet />
    </div>
  );
};

export default MarketplaceLayout;
