import axios from 'axios';
import { SignupModel } from 'models/SignupModel';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASEURL,
});

export const postNewUser = async (values: SignupModel): Promise<{ hasError: boolean; response: any }> => {
    try {
        const response = await api.post('/users/signup', values);
        return {
            hasError: false,
            response: response.data
        };
    } catch (error: any) {
        const errorMessage = error?.response?.data?.message || 'Une erreur est survenue lors de l\'inscription';

        return {
            hasError: true,
            response: errorMessage
        };
    }
};
