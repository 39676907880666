import { SignIn } from '@clerk/clerk-react';
import React from 'react';


const SignInPerso: React.FC = () => {

  return (
    <div className="min-h-screen flex justify-center items-center flex-col">
      <SignIn 
        signUpUrl="/inscription" 
        appearance={{
          elements: {
            formButtonPrimary: 'bg-primary hover:bg-primary-100 text-sm',
            headerTitle: 'after:content-[Salut]'
          },
        }}
      />
    </div>
  );
};

export default SignInPerso;