import React, { useState, useEffect } from "react";
import { useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import CartProductList from "./CartProductList";
import { useCart } from "services/CartService";
import { X } from "lucide-react";
import { Button } from "components/ui/button";

const CartDropdown: React.FC<{ closeCart: () => void }> = ({ closeCart }) => {
  const [total, setTotal] = useState(0);
  const [delivery, setDelivery] = useState(7);
  const { isSignedIn } = useUser();
  const navigate = useNavigate();
  const { getCart } = useCart();

  useEffect(() => {
    const cartItems = getCart().filter(product => product.selected === true);
    const cartTotal = cartItems.reduce((sum, item) => sum + (item.price ? item.price : 0) * (item.quantity * item.minimum), 0);
    
    setTotal(cartTotal);
    setDelivery(7);
  }, [getCart]);

  const navigateToTunnel = () => {
    navigate('/boutique/checkout');
    window.scrollTo(0, 0);
    closeCart()
  };

  return (
    <div className="fixed right-0 top-0 w-full md:w-2/3 lg:w-1/2 xl:w-2/6 h-screen bg-white shadow-lg py-4 z-50">

      <div className="grid grid-cols-[1fr_auto] items-center mb-4 px-4" style={{ backgroundColor: "white" }}>
        <h2 className="text-xl font-bold">Panier</h2>
        <Button onClick={closeCart}>
          <X />
        </Button>
      </div>
      <hr />

      <div className="overflow-y-auto h-[calc(100vh-128px)] px-4 mt-4" style={{ scrollbarWidth: "none", WebkitOverflowScrolling: "touch" }}>
        <CartProductList />
      </div>

      <div className="fixed w-full md:w-2/3 lg:w-1/2 xl:w-2/6 bottom-0 right-0 px-4 bg-white">
        <hr />
        <div className="grid grid-cols-[auto_auto] items-center justify-end my-2">
          <span className="text-lg mr-2" style={{ color: "#637381" }}>Total : </span>
          <span className="text-xl font-semibold">{total.toFixed(2)}€ HT</span>
        </div>
        {total < 100 && <p className="text-right pb-2 text-sm text-accent">Minimum de 100€ de commande non atteint</p>}
        <p className="text-right pb-2 text-sm text-gray-500">livraison : {delivery} € HT</p>
        <hr className="mb-2" />
        {!isSignedIn ? (
          <Button href="/connexion" className="mb-2">
            Se connecter pour commander
          </Button>
        ) : (
          <Button
            disabled={getCart().filter(product => product.selected === true).length === 0 || total < 100}
            onClick={navigateToTunnel}
            className={`w-full mb-2 ${getCart().filter(product => product.selected === true).length === 0 ? 'opacity-50 cursor-not-allowed' : 'opacity-100'}`}
          >
            Commander
          </Button>
        )}
      </div>
    </div>
  );
};

export default CartDropdown;