import React, { useEffect, useState } from 'react';
import OrderMenuCard from 'components/Globals/Cards/OrderCard';
import OrderView from 'components/Dashboard/OrderView';
import { useUser } from '@clerk/clerk-react';
import useOrdersService from 'api/orders/OrdersServices';
import { OrderModelDB } from 'models/dao/OrderModelDB';
import moment from 'moment';

const Orders: React.FC = () => {
  const { user } = useUser();
  const [orders, setOrders] = useState<OrderModelDB[] | []>([])
  const [orderSelect, setOrderSelect] = useState<OrderModelDB | null>(null)
  const { getUserOrders } = useOrdersService();

  const selectOrder = (id:string) => {
    const getTheOrder = orders.find((e) => e.id === id)
    setOrderSelect(getTheOrder || null)
    console.log(getTheOrder)
  }

  const checkDate = (date:string) => {
    if (moment(date).isBefore(moment())) {
      return `Livré le : ${moment(date).format('L')}`
    } else {
      return `Livraison prévue le : ${moment(date).format('L')}`
    }
  }

  useEffect(() => {
    const getOrders = async () => {
      const response = await getUserOrders(user ? user.id : '')
      setOrders(response)
      setOrderSelect(response[0] || null)
    }

    getOrders()
    // eslint-disable-next-line
  }, [user])

  return (
    <div className="container flex gap-4">
      {orders.length < 1 ?
        <div className='flex justify-center items-center w-full'>
          <div className='rounded-sm shadow-sm bg-white mb-2 p-8 mt-32'>
            <h2 className='text-center text-xl font-bold'>Vous n'avez pas encore de commande à traiter</h2>
            <p className='text-center text-md text-gray-500'>
              Vous pourrez retrouver ici toutes les commmandes passées et en cours.
            </p>
          </div>
        </div>
      :
        <>
          <div>
            <h3 className='text-md font-bold mb-2'>Toutes mes commandes</h3>
            <ul className='grid gap-2'>
              {orders?.map((order, index) => (
                <OrderMenuCard 
                  key={index} 
                  selectOrder={() => selectOrder(order.id)} 
                  id={order.id} 
                  state={order.paid} 
                  deliveryDate={checkDate(order.expectedDeliveryDate)} 
                  isSelected={orderSelect?.id === order.id}
                />
              ))}
            </ul>
          </div>
          <div className='flex-1 rounded-sm shadow-sm p-4 bg-white mt-8 border-2'>
            {orderSelect && <OrderView order={orderSelect} date={checkDate(orderSelect.expectedDeliveryDate)} />}
          </div>
        </>
      }
    </div>
  );
};

export default Orders;
