import React from 'react';
import CategoryButton from '../Buttons/CategoryButton';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from 'components/ui/carousel';
import { getAllCategories } from 'api/products/ProductCategoryService';
import { useContextProvider } from 'api/ContextProvider';
import { switchCategory } from 'api/CategoryService';
import { updateFilters } from 'api/products/ProductFilterService';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

const CategoryList: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const categories = getAllCategories();
  const { selectedCategory, setSelectedCategory, filters, setFilters } = useContextProvider();
  const { organizationId } = useParams();

  const handleCategoryClick = (categoryName: string) => {
    // Context filters
    let currentCategoryFilter = filters.find((filter) => filter.typeFilter === "category");
    let filter;
    if (currentCategoryFilter?.value === categoryName) {
      // The category clicked is the same as the current category selected, so it needs to be deselected.
      switchCategory("all");
      filter = { typeFilter: "category", value: "all" };
      setSelectedCategory("all");
    } else {
      switchCategory(categoryName);
      filter = { typeFilter: "category", value: categoryName };
      setSelectedCategory(categoryName);
    }
    updateFilters(setFilters, filter);
    // If on main page, redirects to shop
    if (!location.pathname.endsWith('/produits')) {
      navigate(`/boutique/${organizationId}/produits`);
    }
  };

  return (
    <Carousel
      opts={{
        align: "start",
      }}
      className="container"
    >
      <CarouselContent>
        {categories.map((category, index) => (
          <CarouselItem key={index} className="basis-1/2 sm:basis-1/4 md:basis-1/5 lg:basis-1/6">
            <CategoryButton
              key={index}
              id={category.id}
              icon={<>{category.icon}</>}
              name={category.name}
              onClick={() => handleCategoryClick(category.id)}
              selected={selectedCategory ? selectedCategory : "all"}
            />
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious variant="contain-white" className='left-2 sm:left-0 border-2' />
      <CarouselNext variant="contain-white" className='right-2 sm:right-0 border-2' />
    </Carousel>
  );
};

export default CategoryList;
