import React, { useEffect, useState } from 'react';
import ProductList from '../Globals/Grids/ProductList';
import useProducersService from 'api/producers/ProducerService';
import { ProductModel } from 'models/ProductModel';
import { useParams } from 'react-router-dom';

const MarketplaceDisplayProduct: React.FC = () => {
  const [products, setProducts] = useState<ProductModel[] | []>([]);
  let { organizationId } = useParams();
  const { getOrganizationProducts } = useProducersService();

  const shuffleArray = (array: any[]) => {
    let currentIndex = array.length;
  
    while (currentIndex !== 0) {
      // Select random index
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // Switch with current index
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]
      ];
    }
  
    return array;
  }

  useEffect(() => {
    const getProducts = async () => {
      const organizationProducts = await getOrganizationProducts({ organizationId: organizationId ? organizationId : 'org_2mA6fQMQobyFQFjByLri9x3tTi0' });
      setProducts(shuffleArray(organizationProducts));
    };
    getProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  return (
    <ProductList products={products.slice(0, 10)} />
  );
};

export default MarketplaceDisplayProduct;