import React, { createContext, useState, useContext, useEffect, ReactNode } from "react";
import useProductsService from 'api/products/ProductService';
import { ProductModel } from "models/ProductModel";
import { useContextProvider } from "api/ContextProvider";

interface CartContextType {
  cart: ProductModel[];
  addToCart: (productId: string, quantity: number, selected: boolean) => void;
  removeFromCart: (productId: string, stockToRemove: number) => void;
  getCart: () => ProductModel[];
  updateCart: (newCart: ProductModel[]) => void;
  clearCart: () => void;
  updateCartItemSelected: (productId: string, selected: boolean) => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

interface CartProviderProps {
  children: ReactNode;
}

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [cart, setCart] = useState<ProductModel[]>([]);
  const { allProducts } = useContextProvider();
  const { getProductById } = useProductsService();

  useEffect(() => {
    const existingCart = localStorage.getItem('cart');
    if (existingCart) {
      setCart(JSON.parse(existingCart));
    }
  }, []);

  const getCart = (): ProductModel[] => {
    const cart = localStorage.getItem("cart");
    // Check if all items are available (still in products)
    if (cart && allProducts) {
      const parsedCart: ProductModel[] = JSON.parse(cart);
      const updatedCart = parsedCart.map(cartItem => {
        const isAvailable = allProducts.some(product => product.productId === cartItem.productId);
        if (!isAvailable) {
          cartItem.selected = false; // If product is not available, it should be selected
      }
        return {
          ...cartItem,
          isAvailable: isAvailable,
        };
      });
      return updatedCart;
    }

    return cart ? JSON.parse(cart) : [];
  }

  const addToCart = async (productId: string, quantity: number, selected: boolean) => {
    let fullProduct = await getProductById({ productId: productId });
    fullProduct.quantity = 1;
    fullProduct.selected = true;

    
    setCart(prevCart => {
      const existingProductIndex = prevCart.findIndex(item => item.productId === productId);
      let updatedCart;
      if (existingProductIndex !== -1) {
        updatedCart = [...prevCart];
        if (updatedCart[existingProductIndex]?.quantity && quantity) { // (updatedCart[existingProductIndex]?.quantity + quantity <= updatedCart[existingProductIndex]?.stock)
          updatedCart[existingProductIndex].quantity += quantity;
        }
      } else {
        updatedCart = [...prevCart, fullProduct];
      }
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const removeFromCart = (productId: string, stockToRemove: number) => {
    setCart(prevCart => {
      const existingProductIndex = prevCart.findIndex(item => item.productId === productId);
      if (existingProductIndex !== -1) {
        const updatedCart = [...prevCart];
        const existingProduct = updatedCart[existingProductIndex];
        // if (existingProduct.quantity) {
          if (existingProduct.quantity > stockToRemove) {
            existingProduct.quantity -= stockToRemove;
          } else {
            updatedCart.splice(existingProductIndex, 1);
          }
        // }
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        return updatedCart;
      }
      return prevCart;
    });
  };

  const updateCart = (newCart: ProductModel[]) => {
    setCart(newCart);
    localStorage.setItem('cart', JSON.stringify(newCart));
  };

  const updateCartItemSelected = (productId: string, selected: boolean) => {
    setCart(prevCart => {
      const updatedCart = prevCart.map(item =>
        item.productId === productId ? { ...item, selected } : item
      );
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem('cart');
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, getCart, updateCart, clearCart, updateCartItemSelected }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};