import React from 'react';
import ReactDOM from 'react-dom/client'
import './index.css';
import App from './App';
import { ClerkProvider } from '@clerk/clerk-react';
import { ContextProvider } from 'api/ContextProvider';
import { frFR } from '@clerk/localizations'

const PUBLISHABLE_KEY = process.env.REACT_APP_VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY} localization={frFR}>
      <ContextProvider>
        <App />
      </ContextProvider>
    </ClerkProvider>
  </React.StrictMode>,
)