import React from 'react';
import ProductsCategoryLayout from 'layouts/products/ProductsCategoryLayout';
import ProductListLayout from 'layouts/products/ProductListLayout';
import MarketplaceDelivery from 'components/Marketplace/MarketplaceDelivery';

const AllProducts: React.FC = () => {
  return (
      <div>
        <div className='bg-[#F3DFAC]'>
          <ProductsCategoryLayout />
        </div>
        <div className='container'>
          <ProductListLayout />
        </div>
        <MarketplaceDelivery />
      </div>
    );
};

export default AllProducts;