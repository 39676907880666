import React, { useEffect, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { useContextProvider } from 'api/ContextProvider';
import { updateFilters } from 'api/products/ProductFilterService';
import { useLocation } from 'react-router-dom';
import { ProducerModel } from 'models/ProducerModel';

interface FilterBarProps {
  totalPages: number;
  itemsPerPage: number;
  totalProducts: number;
  currentPage: number;
  producers?: ProducerModel[];
}

const FilterBar: React.FC<FilterBarProps> = ({ producers, totalPages, itemsPerPage, currentPage, totalProducts }) => {
  const { setFilters } = useContextProvider();
  const [selectedProducer, setSelectedProducer] = useState<string | undefined>();
  const location = useLocation();
  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };
  const queryParams = getQueryParams(location.search);
  const producerId = queryParams.get('producerId');

  useEffect(() => {
    if (producers && producerId) {
      if (producers.some((producer) => producer.id === producerId)) {
        setSelectedProducer(producerId);
      }
    }

  }, [producerId, producers]);

  const selectFilter = (filterValue: string, filterType: string) => {
    let filter = { typeFilter: filterType, value: filterValue };
    updateFilters(setFilters, filter);
    if (filterType === 'sortProducer') {
      setSelectedProducer(filterValue);
    }
  }

  return (
    <div className="flex flex-col-reverse lg:flex-row lg:justify-between items-start lg:items-center py-4 px-2">
      <div className="text-sm mt-2 lg:mt-0">
        {totalProducts > 0 ?
          <>{1 + (itemsPerPage * (currentPage - 1))} à {Math.min(itemsPerPage * currentPage, totalProducts)} {totalProducts > 1 ? "produits" : "produit"} sur {totalProducts} {totalProducts > 1 ? "résultats" : "résultat"} - {totalPages} {totalPages > 1 ? "pages" : "page"}</>
          :
          <>Aucun résultat.</>
        }
      </div>
      <div className="flex flex-col space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
        <Select onValueChange={(value) => selectFilter(value, 'sortProducer')} value={selectedProducer}>
          <SelectTrigger className="w-[250px] sm:w-[180px] border border-gray-300 rounded-md p-2 bg-[#fafafa]">
            <SelectValue placeholder="Producteur" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Producteur</SelectLabel>
              {producers?.map((producer) => (
                <SelectItem key={producer.id} value={producer.id}>
                  {producer.factoryName}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>

        <Select onValueChange={(value) => selectFilter(value, 'sortPrice')}>
          <SelectTrigger className="w-[250px] sm:w-[180px] border border-gray-300 rounded-md p-2 bg-[#fafafa]">
            <SelectValue placeholder="Prix" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Prix</SelectLabel>
              <SelectItem value="asc">Prix croissant</SelectItem>
              <SelectItem value="desc">Prix décroissant</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>

        <Select onValueChange={(value) => selectFilter(value, 'sortName')}>
          <SelectTrigger className="w-[250px] sm:w-[180px] border border-gray-300 rounded-md p-2 bg-[#fafafa]">
            <SelectValue placeholder="Trier" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Trier</SelectLabel>
              <SelectItem value="asc">A à Z</SelectItem>
              <SelectItem value="desc">Z à A</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

export default FilterBar;