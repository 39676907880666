import React from 'react';
import building from 'assets/building.png';
import CalendarCard from 'components/Globals/Cards/CalendarCard';
// import { getNextDay } from 'api/DateService';
// import { DateModel } from 'models/DateModel';

const MarketplaceDelivery: React.FC = () => {
  // const [sortedDays, setSortedDays] = useState<DateModel[]>([]);

  // useEffect(() => {
  //   const getDates = () => {
  //     const tuesday = getNextDay({ day: "mardi" });
  //     const friday = getNextDay({ day: "vendredi" });

  //     const dates = [tuesday, friday];

  //     // Trie les dates en fonction de leur proximité avec la date actuelle
  //     const sortedDates = dates.sort((a, b) => {
  //       const today = new Date();
  //       const dateA = new Date(today.getFullYear(), today.getMonth(), parseInt(a.number));
  //       const dateB = new Date(today.getFullYear(), today.getMonth(), parseInt(b.number));

  //       return dateA.getTime() - dateB.getTime();
  //     });

  //     setSortedDays(sortedDates);
  //   };
  //   getDates();
  // }, []);

  return (
    <div className='mt-[10%] relative overflow-hidden' style={{ backgroundColor: "#F3DFAC" }}>
      <div className="container py-10 md:py-20 relative z-10">
        <div className="flex flex-wrap w-[90%] gap-10">
          <div className="md:max-w-xl">
            <h1 className="text-4xl md:text-5xl font-bold">
              Livré chez vous les jeudis
            </h1>
            <h2 className="text-md md:text-xl font-normal mt-2">
              Avec Mullo mes commandes en direct des producteurs sont livrées devant mon établissement.
            </h2>
          </div>

          <div className="flex gap-4">
            <div key={1} className="flex-shrink-0">
              <CalendarCard date={"21"} day={'jeudi'} />
            </div>
            <div key={1} className="flex-shrink-0">
              <CalendarCard date={"28"} day={'jeudi'} />
            </div>
            {/* {sortedDays.map((dateInfo, i) => (
              <div key={i} className="flex-shrink-0">
                <CalendarCard key={dateInfo.day} date={dateInfo.number} day={dateInfo.day} />
              </div>
            ))} */}
          </div>

        </div>
      </div>
      <img src={building} className="absolute bottom-0 right-0 w-[60%] md:w-[40%] lg:w-[20%]" alt="" />
    </div>
  );
};

export default MarketplaceDelivery;