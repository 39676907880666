import React, { Dispatch, SetStateAction } from 'react';
import { Check, Clock } from 'lucide-react';

const OrderMenuCard: React.FC<{ selectOrder: Dispatch<SetStateAction<string>>, id: string, state: boolean, deliveryDate: string, isSelected: boolean }> = ({selectOrder, id, state, deliveryDate, isSelected}) => {
  
  const displayIcon = () => {
    if (state) {
      return <Check size={24} className='text-emerald-700' />
    } else {
      return <Clock size={24} className='text-amber-700'/>
    }
  }

  return (
    <li 
      onClick={() => selectOrder(id)} 
      className={`text-sm text-slate-600 cursor-pointer p-2 hover:bg-slate-100 ${isSelected && 'bg-slate-200'} rounded-md flex items-center bg-white border-zinc-200 border-2`}
    >
      <div className={`${state ? 'bg-emerald-300' : 'bg-amber-300'} p-2 flex items-center justify-center aspect-square rounded-sm`}>
        {displayIcon()}
      </div>
      <div className='ml-2 mr-2'>
        <p className='text-base text-nowrap'>Commande N°{id.substring(id.length-6)}</p>
        <p className='text-xs text-zinc-400 font-bold'>{deliveryDate}</p>
      </div>
    </li>
  );
};

export default OrderMenuCard;
