import React from 'react'
import AddressForm from 'components/Globals/Forms/AddressForm'

const SendAddress: React.FC = () => {

  return (
    <div className='container p-8 rounded-sm'>
      <AddressForm />
    </div>
  )
}

export default SendAddress