import React, { useState, useEffect } from 'react';
import { ProductModel } from 'models/ProductModel';
import ProductList from './ProductList';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "components/ui/pagination";
import FilterBar from 'components/Globals/Filter/FilterBar';
import useProducersService from 'api/producers/ProducerService';
import { useLocation, useParams } from 'react-router-dom';
import { useContextProvider } from 'api/ContextProvider';
import useProductsService from 'api/products/ProductService';
import { ProducerModel } from 'models/ProducerModel';
import { updateFilters } from 'api/products/ProductFilterService';

const ProductShopList: React.FC = () => {
  const { getOrganizationProducers, getOrganizationProducts } = useProducersService();
  const { getFilteredProducts } = useProductsService();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState<ProductModel[]>([]);
  const [producers, setProducers] = useState<ProducerModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  let { organizationId } = useParams();
  const { filters, setFilters } = useContextProvider();
  const location = useLocation();
  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };
  const queryParams = getQueryParams(location.search);
  const producerId = queryParams.get('producerId');

  // Calculate numbers of items per page: first time
  const calculateItemsPerPage = (): number => {
    const width = window.innerWidth;
    let columns;
    if (width >= 1280) columns = 5; // xl:grid-cols-6
    else if (width >= 1024) columns = 4; // lg:grid-cols-5
    else if (width >= 768) columns = 4; // md:grid-cols-4
    else if (width >= 640) columns = 3; // sm:grid-cols-3
    else columns = 2; // grid-cols-2
    return columns * 6;
  };

  // Calculate number of items per page
  const updateItemsPerPage = () => {
    const newItemsPerPage = calculateItemsPerPage();
    setItemsPerPage(newItemsPerPage);
    setTotalPages(Math.ceil(filteredProducts.length / newItemsPerPage));
  };

  useEffect(() => {
    const fetchProducers = async () => {
      try {
        const organizationProducers = await getOrganizationProducers({
          organizationId: organizationId || 'org_2mA6fQMQobyFQFjByLri9x3tTi0',
        });
        setProducers(organizationProducers);
      } catch (error) {
        console.error('Error fetching producers:', error);
      }
    };

    if (producerId) {
      setLoading(true);
      let filter = { typeFilter: "sortProducer", value: producerId };
      updateFilters(setFilters, filter);
      setLoading(false);
    }
    fetchProducers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);


  useEffect(() => {
    // Fetch products and apply filters
    const fetchAndFilterProducts = async () => {
      setLoading(true);
      // Fetch products
      const organizationProducts = await getOrganizationProducts({
        organizationId: organizationId || 'org_2mA6fQMQobyFQFjByLri9x3tTi0',
      });

      // Filter products
      const filtered = await getFilteredProducts(organizationProducts, filters);
      setFilteredProducts(filtered);
      updateItemsPerPage();
      setTotalPages(Math.ceil(filtered.length / itemsPerPage));
      setCurrentPage(1);
      setLoading(false);
    };
    fetchAndFilterProducts();
    window.addEventListener('resize', updateItemsPerPage);

    return () => {
      window.removeEventListener('resize', updateItemsPerPage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, filters, itemsPerPage]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className={loading ? 'cursor-wait' : ''}>
      {/* Filters */}
      <FilterBar producers={producers} currentPage={currentPage} totalPages={totalPages} itemsPerPage={itemsPerPage} totalProducts={filteredProducts.length} />
      {/* Product list */}
      <ProductList products={paginatedProducts} />
      {totalPages > 0 &&
        <Pagination className='mt-6'>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handlePageChange(Math.max(currentPage - 1, 1));
                }}
                className="bg-white hover:bg-gray-100 rounded-l-md rounded-r-none border border-gray-300"
              />
            </PaginationItem>
            {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
              <PaginationItem key={page}>
                <PaginationLink
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handlePageChange(page);
                  }}
                  className={`${currentPage === page ? 'bg-[#F3DFAC] text-white' : 'bg-white'} border border-gray-300 rounded-none`}
                >
                  {page}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem>
              <PaginationNext
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handlePageChange(Math.min(currentPage + 1, totalPages));
                }}
                className="bg-white hover:bg-gray-100 rounded-r-md rounded-l-none border border-gray-300"
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      }
    </div>
  );
};


export default ProductShopList;
