import React, { useState, useEffect } from 'react';
import { useCart } from '../../../services/CartService';
import { ProductModel } from 'models/ProductModel';
import { CircleMinus, CirclePlus } from 'lucide-react';
import { Button } from 'components/ui/button';

interface SimpleProductCardProps extends ProductModel {
  onClickProduct: (productId: string) => void;
}

const SimpleProductCard: React.FC<SimpleProductCardProps> = ({ image, price, minimum, productName, productId, unitFormat, onClickProduct }) => {
  const [quantity, setQuantity] = useState(0);
  const { addToCart, removeFromCart, getCart } = useCart();

  useEffect(() => {
    const cart = getCart();
    const product = cart.find(item => item.productId === productId);
    if (product) {
      setQuantity(product.quantity);
    }
  }, [productId, getCart]);

  const handleAddToCart = () => {
    addToCart(productId, 1, true);
  };

  const handleRemoveFromCart = () => {
    removeFromCart(productId, 1);
  };

  return (
    <div className="m-2 flex flex-col gap-2 bg-white rounded-md shadow-sm" onClick={() => onClickProduct(productId)}>
      <div 
        className="bg-[#A0A0A0] rounded-t-md h-44 bg-cover bg-no-repeat bg-center cursor-pointer hover:opacity-85" 
        style={{backgroundImage: `url(${image})`}} 
      />
      <div className='flex justify-between items-center px-2'>
        <div>
          <p className="text-md font-bold text-black">{(price*minimum).toFixed(2)}€ ({minimum + " " + unitFormat})</p>
          <p className="text-xs text-[#A0A0A0]">{price.toFixed(2)}€/{unitFormat}</p>
        </div>
        <div>
          {quantity > 0 ? (
            <div className="flex items-center ml-1">
              <CircleMinus 
                onClick={handleRemoveFromCart} 
                size={30} 
                className='bg-white text-black cursor-pointer hover:bg-gray-100 rounded-full' 
              />
              <span className="text-md font-bold mx-2">{quantity}</span>
              <CirclePlus 
                onClick={handleAddToCart} 
                size={30} 
                className='bg-white text-black cursor-pointer hover:bg-gray-100 rounded-full' 
              />
            </div>
          ) : (
            <Button onClick={handleAddToCart} size={"sm"} variant="outline-muted" className='border-black text-black'>
              Ajouter
            </Button>
          )}
        </div>
      </div>
      <div className='px-2 mb-2 h-20'>
        <hr/>
        <h4 className="text-lg mt-2 text-black">{productName}</h4>
      </div>
    </div>
  );
};

export default SimpleProductCard;
