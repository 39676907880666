import axios from 'axios';
import { AddAddressModel } from 'models/AddAddressModel';
import { AddressModel } from 'models/AddressModel';
import { AddressModelDB } from 'models/dao/AddressModelDB';
import { useAuth } from '@clerk/clerk-react';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
});

const useAddressesService = () => {
  const { getToken } = useAuth();


  const getUserAddresses = async (userId: string): Promise<AddressModel[]> => {
    try {
      const token = await getToken();
      const response = await api.get(`/users/${userId}/addresses`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const formattedAddresses = response.data.records.map((address: AddressModelDB) => ({
        id: address.id,
        userId: address.userId,
        name: address.name,
        street: address.street,
        zipCode: address.zipCode,
        city: address.city,
        country: address.country,
      }));
      return formattedAddresses;
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error('Failed to fetch addresses: ' + error.message);
      } else {
        throw new Error('Failed to fetch addresses: Unknown error');
      }
    }
  };

  const postUserAddress = async (address: AddAddressModel): Promise<{ hasError: boolean; response: any }> => {
    try {
      const token = await getToken();
      const response = await api.post(`/addresses`, {
        userId: address.userId,
        name: address.name,
        street: address.street,
        zipCode: address.zipCode,
        city: address.city,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return {
        hasError: false,
        response: response.data,
      };
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error('Failed to create new address: ' + error.message);
      } else {
        throw new Error('Failed to create new address: Unknown error');
      }
    }
  };

  return { getUserAddresses, postUserAddress };
};

export default useAddressesService;