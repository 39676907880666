import React from 'react';

import MarketplaceSearch from 'components/Marketplace/MarketplaceSearch';
import MarketplaceCategory from 'components/Marketplace/MarketplaceCategory';
import MarketplaceProduct from 'components/Marketplace/MarketplaceProduct';
import MarketplaceDelivery from 'components/Marketplace/MarketplaceDelivery';
import ProducerList from 'components/Globals/Grids/ProducerList';

const MarketPlace: React.FC = () => {
  return (
    <div style={{ maxWidth: '100vw', overflowX: 'hidden' }}>
      <MarketplaceSearch />
      <div id="categoriesSection" className='mt-[-65px]'>
        <MarketplaceCategory />
      </div>
      <MarketplaceProduct />
      <div id="producersSection">
        <ProducerList />
      </div>
      <MarketplaceDelivery />
    </div>
  );
};

export default MarketPlace;
