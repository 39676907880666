import React from 'react';
import ProductShopList from 'components/Globals/Grids/ProductShopList';


const ProductListLayout: React.FC = () => {

  return (
    <div>
      <ProductShopList />
    </div>
  );
};

export default ProductListLayout;