import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { ProductModel } from 'models/ProductModel';
import useProductsService from './products/ProductService';
import { FilterModel } from 'models/FilterModel';

interface ContextProviderType {
  selectedCategory: string | null;
  setSelectedCategory: (category: string) => void;
  allProducts: ProductModel[] | null;
  filters: FilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<FilterModel[]>>;
}

const ProviderContext = createContext<ContextProviderType | undefined>(undefined);

export const ContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [allProducts, setAllProducts] = useState<ProductModel[] | null>(null);
  const [filters, setFilters] = useState<FilterModel[]>([]);
  const { getAllProducts } = useProductsService();

  useEffect(() => {
    const getProducts = async () => {
      const products = await getAllProducts();
      setAllProducts(products);
    };
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProviderContext.Provider value={{ selectedCategory, setSelectedCategory, allProducts, filters, setFilters }}>
      {children}
    </ProviderContext.Provider>
  );
};

export const useContextProvider = () => {
  const context = useContext(ProviderContext);
  if (!context) {
    throw new Error("useContextProvider must be used within a ContextProvider");
  }
  return context;
};