import { Button } from 'components/ui/button';
import React from 'react';

interface InformationCardProps {
  image: string;
  title: string;
  subtitle: string;
  onClick: () => void;
  backgroundColor: string;
  btnText: string;
  disabled: boolean;
}

const InformationCard: React.FC<InformationCardProps> = ({ image, title, subtitle, onClick, backgroundColor, btnText, disabled }) => {
  return (
    <div
      className="relative flex rounded-lg"
      style={{
        backgroundColor,
        border: '1px solid #EADFDF',
        height: '200px',
      }}
    >
      <div className="flex flex-col justify-center items-start p-4 w-3/5 h-full">
        <div>
          <h2 className="text-lg md:text-2xl font-bold">{title}</h2>
          <p className="text-sm mt-1">{subtitle}</p>
        </div>
        <Button
          onClick={onClick}
          disabled={disabled}
          className="mt-4 p-2 bg-white text-black border-none rounded-md text-sm transition duration-300 ease-in-out hover:bg-green-800 hover:text-white"
        >
          {btnText}
        </Button>

      </div>
      <div className="absolute bottom-0 right-0 w-2/5 h-full m-0">
        <img src={image} alt={title} className="object-contain absolute bottom-0 right-0" />
      </div>
    </div>
  );
};

export default InformationCard;
