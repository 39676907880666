import { FilterModel } from 'models/FilterModel';

/**
 * Update the filters used in context.
 * 
 * @param {FilterModel[]} filters - The current list of filters.
 * @param {React.Dispatch<React.SetStateAction<FilterModel[]>>} setFilters - The function to update the filters.
 * @param {FilterModel} newFilter - The new filter to update or add.
 * @returns {Promise<FilterModel[]>} - The updated list of filters.
 */
export const updateFilters = (
    setFilters: React.Dispatch<React.SetStateAction<FilterModel[]>>, 
    newFilter: FilterModel
): Promise<FilterModel[]> => {
    return new Promise((resolve, reject) => {
        try {
            setFilters((prevFilters) => {
                const filterIndex = prevFilters.findIndex(filter => filter.typeFilter === newFilter.typeFilter);

                if (filterIndex !== -1) {
                    const updatedFilters = [...prevFilters];
                    updatedFilters[filterIndex].value = newFilter.value;
                    resolve(updatedFilters);
                    return updatedFilters;
                } else {
                    // Add new filter
                    const updatedFilters = [...prevFilters, newFilter];
                    resolve(updatedFilters);
                    return updatedFilters;
                }
            });
        } catch (error) {
            reject(error);
        }
    });
};