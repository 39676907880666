import NavBar from 'components/Globals/Navs/NavBar';
import React from 'react';
import { Outlet } from 'react-router-dom';

const DashboardLayout: React.FC = () => {

  return (
    <div className='pt-28 min-h-screen'>
      <NavBar />
      <Outlet />
    </div>
  );
};

export default DashboardLayout;
