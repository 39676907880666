import React, { useState, useEffect } from 'react';
import { Button } from "components/ui/button";
import { useContextProvider } from "api/ContextProvider";
import { useNavigate } from 'react-router-dom';

interface SearchBarProps {
  size: 'full' | 'medium';
}

const SearchBar: React.FC<SearchBarProps> = ({ size }) => {
  const navigate = useNavigate()
  const { allProducts } = useContextProvider();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);

  // Filter products
  useEffect(() => {
    if (searchTerm.length >= 4 && allProducts && allProducts.length > 0) {
      const filtered = allProducts.filter((product: any) =>
        product.productName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  }, [searchTerm, allProducts]);
  

  const handleProductSelect = (productId: string, productName: string) => {
    setSearchTerm(productName);
    setFilteredProducts([]);
    navigate(`produits?=${productId}`)
  };

  return (
    <div className='relative'>
      {size === 'full' && (
        <div className="relative flex items-center w-full rounded-sm bg-[#F9FAFB] p-2">
          <input
            type="text"
            className="flex-grow text-sm text-gray-700 placeholder-italic placeholder-gray-500 bg-transparent focus:outline-none"
            placeholder="Rechercher un article. ex: salades, farine, épicerie"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            className="ml-2 bg-[#273E2C] text-white rounded-md font-semibold"
            type="button"
            size="sm"
          >
            Rechercher
          </Button>
        </div>
      )}

      {size === 'medium' && (
        <div className="flex items-center rounded-md bg-[#f9fafb] py-3 px-6 gap-x-2" style={{ padding: '12px 24px' }}>
          <input
            type="text"
            placeholder="Rechercher un article. Ex: salades, farine, épicerie... "
            className="flex-grow text-sm italic placeholder-gray-500"
            style={{
              backgroundColor: '#f9fafb',
              border: 'none',
              outline: 'none',
              fontSize: '16px',
              color: '#999999',
            }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            className="bg-[#273E2C] text-white rounded-md py-2 px-4"
            style={{ borderRadius: '5px' }}
          >
            Rechercher
          </Button>
        </div>
      )}

      {searchTerm.length >= 4 && (
        <ul className="absolute w-full h-80 overflow-y-scroll mt-1 bg-white border border-gray-200 rounded-lg z-10">
          {filteredProducts.map((product) => (
            <li
              key={product.productId}
              className="flex items-center p-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleProductSelect(product.id, product.productName)} 
            >
              <div className="flex flex-col">
                <span className="font-bold text-gray-900">{product.productName}</span>
                <span className="text-gray-700">
                  {product.price ? `${product.price} €` : 'Prix non disponible'}
                </span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;