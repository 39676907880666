import React from 'react';
import CategoryList from 'components/Globals/Caroussels/CategoryList';

const ProductsCategoryLayout: React.FC = () => {

    return (
      <div className="container px-2 mt-16 mb-20">
        <div className="relative top-[63px]">
          <CategoryList />
        </div>
      </div>
    );
};

export default ProductsCategoryLayout;