import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar"

interface SimpleProducerCardProps {
  image?: string;
  producerName: string;
  factoryName: string;
}

const SimpleProducerCard: React.FC<SimpleProducerCardProps> = ({ image, producerName, factoryName }) => {
  return (
    <div>
      <Avatar className='w-36 sm:w-32 md:w-36 h-auto m-auto'>
        <AvatarImage src={image} alt={producerName} />
        <AvatarFallback>{producerName.slice(0,1).toUpperCase()}</AvatarFallback>
      </Avatar>
      <p className="text-md text-center italic text-[#A0A0A0]">{factoryName ? factoryName : producerName}</p>
    </div>
  );
};

export default SimpleProducerCard;
