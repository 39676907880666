import { useUser } from '@clerk/clerk-react';
import useAddressesService from 'api/addresses/addressesService';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { AddressModel } from 'models/AddressModel';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const AddressForm: React.FC = () => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const { getUserAddresses, postUserAddress } = useAddressesService();
    const { user } = useUser();
    const [message, setMessage] = useState("");
    const [redirect, setRedirect] = useState(false);
    const navigate = useNavigate();
    const [addresses, setAddresses] = useState<AddressModel[]>([]);
    const [mainAddress, setMainAddress] = useState<AddressModel | null>(null);

    useEffect(() => {
        const getAddresses = async () => {
            if (user?.id) {
                const userAddresses = await getUserAddresses(user.id);
                setAddresses(userAddresses);
                setMainAddress(userAddresses[userAddresses.length - 1]);
            }
        };
        getAddresses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id]);

    useEffect(() => {
        if (mainAddress) {
            setValue('name', mainAddress.name || "Adresse principale");
            setValue('street', mainAddress.street);
            setValue('zipCode', mainAddress.zipCode);
            setValue('city', mainAddress.city);
        }
    }, [mainAddress, setValue]);

    const onSubmit = async (data: any) => {
        if (user?.id) {
            const addressToSend = {
                userId: user.id,
                name: data.name || "Adresse principale",
                street: data.street,
                zipCode: data.zipCode,
                city: data.city
            };
            try {
                const response = await postUserAddress(addressToSend);
                if (!response.hasError) {
                    setMessage("L'adresse a été enregistrée, vous allez être rediriger dans quelques secondes.");
                    setRedirect(true); // Used to disable send button to avoid user clicking too many times.
                    setTimeout(() => {
                        navigate('/');
                    }, 2000);
                }
            } catch (error) {
                setMessage("Une erreur s'est produite lors de l'envoi du formulaire : " + error);
            }
        }
    };


    return (
        <div className="bg-white rounded-lg border border-gray-400 p-6 shadow-sm">
            <h2 className="text-lg font-bold mb-4">
                {addresses.length > 0 ? "Modifier votre adresse" : "Renseigner votre adresse"}
            </h2>

            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                {message && (
                    <div className="text-red-600 text-center mb-4">
                        {message}
                    </div>
                )}
                <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">Nom de l'adresse</label>
                    <Input
                        id="name"
                        type="text"
                        defaultValue={mainAddress ? mainAddress.name : ""}
                        placeholder="Ex : Mon adresse principale"
                        {...register('name', { required: false })}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                </div>
                <div>
                    <label htmlFor="street" className="block text-sm font-medium text-gray-700">Adresse</label>
                    <Input
                        id="street"
                        type="text"
                        defaultValue={mainAddress ? mainAddress.street : ""}
                        {...register('street', { required: true })}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    {errors.street && <span className="text-red-600">Ce champ est requis</span>}
                </div>

                <div className="flex space-x-4">
                    <div className="flex-1">
                        <label htmlFor="zipCode" className="block text-sm font-medium text-gray-700">Code postal</label>
                        <Input
                            id="zipCode"
                            type="text"
                            defaultValue={mainAddress ? mainAddress.zipCode : ""}
                            {...register('zipCode', { required: true })}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                        {errors.zipCode && <span className="text-red-600">Ce champ est requis</span>}
                    </div>

                    <div className="flex-1">
                        <label htmlFor="city" className="block text-sm font-medium text-gray-700">Ville</label>
                        <Input
                            id="city"
                            type="text"
                            defaultValue={mainAddress ? mainAddress.city : ""}
                            {...register('city', { required: true })}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                        {errors.city && <span className="text-red-600">Ce champ est requis</span>}
                    </div>
                </div>

                <Button
                    type="submit"
                    className="mt-4 w-full"
                    disabled={redirect}
                >
                    {mainAddress ? "Modifier votre adresse" : "Ajouter une adresse"}
                </Button>
            </form>
        </div>
    );
};

export default AddressForm;