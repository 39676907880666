import React, { useState, useEffect } from "react";
import CartProductList from "components/Globals/Cart/CartProductList";
import { useCart } from "services/CartService";


const OrderResumeCard: React.FC = () => {
  const [total, setTotal] = useState(0);
  const [totalTtc, setTotalTtc] = useState(0);
  const [delivery, setDelivery] = useState(7);
  const { getCart } = useCart();

  useEffect(() => {
    const cartItems = getCart().filter(product => product.selected === true);
    const cartTotal = cartItems.reduce((sum, item) => sum + (item.price ? item.price : 0) * (item.quantity * item.minimum), 0);
    const cartTotalTtc = cartItems.reduce((sum, item) => sum + (item.price ? item.price + (item.price*item.taxRate/100) : 0) * (item.quantity * item.minimum), 0);

    let deliveryCost = 7
    setDelivery(7);
    setTotal(cartTotal);
    setTotalTtc(cartTotalTtc + (deliveryCost*20/100));
  }, [getCart]);

  return (
    <div className="bg-[#F0F6F1] rounded-lg border border-gray-400 p-6 shadow-sm" >
      <div className="">
        <h1 className="text-xl font-bold mb-4 text-center">Votre commande</h1>
        <div>
          <CartProductList checkout={true} />
        </div>
        <div className="flex justify-end">
          <span className="text-xs mr-1" style={{ color: "#637381" }}>Total HT : </span>
          <span className="text-xs font-semibold">{total.toFixed(2)}€</span>
        </div>
        <div className="flex justify-end">
          <span className="text-xs mr-1" style={{ color: "#637381" }}>Livraison : </span>
          <span className="text-xs font-semibold">{delivery.toFixed(2)}€</span>
        </div>
        <div className="flex justify-end">
          <span className="text-xs mr-1" style={{ color: "#637381" }}>TVA : </span>
          <span className="text-xs font-semibold">{(totalTtc - total).toFixed(2)}€</span>
        </div>
        <div className="flex justify-end">
          <span className="text-sm mr-1" style={{ color: "#637381" }}>Total TTC : </span>
          <span className="font-semibold">{(totalTtc+delivery).toFixed(2)}€</span>
        </div>
      </div>
    </div>
  );
};

export default OrderResumeCard;