import React from 'react'
import CGV from 'assets/pdf/cgv.pdf'
import CGU from 'assets/pdf/cgu.pdf'
import logo from 'assets/logo.svg';
import { Facebook, Instagram, Linkedin } from 'lucide-react'

const Footer: React.FC = () => {

  return (
    <div className='container py-4'>
      <div className='grid grid-cols-3 gap-5'>
        <img
          className="hidden sm:block h-8 w-auto max-w-[150px] sm:max-w-[200px] lg:max-w-[250px] xl:max-w-[300px]"
          src={logo}
          alt="Mullo Logo"
          style={{ maxHeight: '100%' }}
        />

        <div>
          <h4 className='font-bold mb-2'>Contact</h4>
          Notre mail : <a href="mailto:contact@mullo.fr" className='text-primary'>contact@mullo.fr</a>
        </div>
        <div>
          <h4 className='font-bold mb-2'>Nos réseaux</h4>
          <div className='flex space-x-2'>
            <a 
              href="https://www.instagram.com/mullo.fr/" 
              className='bg-neutral-300 hover:bg-neutral-400 w-10 h-10 rounded-full flex justify-center items-center'
            >
              <Instagram color='white' />
            </a>

            <a 
              href="https://www.facebook.com/profile.php?id=61560858286369" 
              className='bg-neutral-300 hover:bg-neutral-400 w-10 h-10 rounded-full flex justify-center items-center'
            >
              <Facebook color='white' />
            </a>

            <a 
              href="https://www.linkedin.com/company/mullo-france" 
              className='bg-neutral-300 hover:bg-neutral-400 w-10 h-10 rounded-full flex justify-center items-center'
            >
              <Linkedin color='white' />
            </a>
          </div>
        </div>
      </div>
      <div className='h-[1px] bg-neutral-300 my-4' />
      <div className='grid grid-cols-3 gap-5'>
        <p className='text-primary'>Droits réservés @mullo</p> 
        <a className='text-primary hover:text-secondary underline' href={CGV} title="Pdf" download="cgv-mullo.pdf">Nos conditions générales de vente</a>
        <a className='text-primary hover:text-secondary underline' href={CGU} title="Pdf" download="cgu-mullo.pdf">Nos conditions générales d'utilisation</a>
      </div>
    </div>
  )
}

export default Footer