import React from 'react';

interface CalendarCardProps {
  date: string;
  day: string;
}

const CalendarCard: React.FC<CalendarCardProps> = ({ date, day }) => {
  return (
    <div className="w-32 h-32 bg-gray-200 rounded-md overflow-hidden">
      <div className="h-3/4 bg-white flex items-center justify-center">
        <p className="text-black text-center font-bold text-5xl">{date}</p>
      </div>
      <div className="h-1/4 bg-[#DDA7A7] flex items-center justify-center">
        <p className="text-white text-center">{day}</p>
      </div>
    </div>
  );
};

export default CalendarCard;