import React, { useState, useEffect } from 'react';
import BillingInfosCard from 'components/Globals/Cards/Checkout/BillingInfosCard';
import DeliveryInfosCard from 'components/Globals/Cards/Checkout/DeliveryInfosCard';
import OrderResumeCard from 'components/Globals/Cards/Checkout/OrderResumeCard';
import { Button } from 'components/ui/button';
import moment from 'moment';
import { useUser } from '@clerk/clerk-react';
import { AddressModel } from 'models/AddressModel';
import useAddressesService from 'api/addresses/addressesService';
import SendAddress from 'pages/dashboard/SendAddress';
// import DiscountCard from 'components/Globals/Cards/Checkout/DiscountCard';

const Checkout: React.FC = () => {
  const { user } = useUser();
  const { getUserAddresses } = useAddressesService();
  const [orderDone, setOrderDone] = useState("");
  const [message, setMessage] = useState("");
  const [addresses, setAddresses] = useState<AddressModel[]>([]);
  const [openAddress, setOpenAddress] = useState<boolean>(false);
  const [orderComplete, setOrderComplete] = useState<boolean>(false);

  useEffect(() => {
    const getAddresses = async () => {
      if (user?.id) {
        const userAddresses = await getUserAddresses(user.id);
        setAddresses(userAddresses);
      }
    };
    getAddresses();
    // Order can't be fullfiled if there's no address
    setOrderComplete(addresses?.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, addresses?.length]);

  const handleAddAddress = () => {
    setOpenAddress(prev => !prev);
  }

  return (
    <div
      className="container min-h-screen"
    >
      {orderDone ?
        <div className=" min-h-screen flex justify-center items-center pb-10">
          <div className='max-w-[400px] rounded-xl shadow-xl px-8 pt-10 pb-4'>
            <h2 className="text-center text-2xl font-bold text-primary mb-6">Votre commande est validée !</h2>
            <p className="text-center text-md font-semibold mb-2">
              Elle sera traîtée dans les plus brefs délais !
            </p>
            <p className="text-center text-sm font-semibold mb-4 text-slate-500">
              Date de livraison estimée : {moment(orderDone).format('L')}
            </p>
            <hr />
            {/* TO DO AFTER GETS ORDERS */}
            <Button href="/dashboard/commandes" variant="contain-secondary" className='w-full mb-3 mt-4'>
              Voir mes commandes
            </Button>
            {/* change the url to dynamic url */}
            <Button href="/boutique/org_2mA6fQMQobyFQFjByLri9x3tTi0" variant="ghost-primary" className='w-full'>
              Retour aux produits
            </Button>
          </div>
        </div>
        :
        <div className='grid grid-cols-3 pb-40 justify-center gap-4 pt-36'>
          {message && <div className="col-span-3 bg-accent rounded-sm p-4">
            <p className='text-white'>{message}</p>
          </div>}
          {/* Left column */}
          <div className="col-span-2">
            <div className="sticky top-28 flex flex-col gap-4">
              <BillingInfosCard addresses={addresses} handleAddAddress={handleAddAddress} />
              <DeliveryInfosCard setOrderDone={setOrderDone} setMessage={setMessage} orderComplete={orderComplete} />
            </div>
          </div>

          {/* Right column */}
          <div className=" top-36 col-span-1 flex flex-col gap-4">
            <OrderResumeCard />
            {/* <DiscountCard /> <= on remettra celui là quand on aura les coupons codes */}
          </div>

          {openAddress && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3 relative">
                <SendAddress />
                <button
                  onClick={() => setOpenAddress(false)}
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                >
                  &times;
                </button>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  );
};

export default Checkout;
