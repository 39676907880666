import { useAuth } from '@clerk/clerk-react';
import axios from 'axios';
import { OrderModelDB } from 'models/dao/OrderModelDB';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
});

const useOrdersService = () => {
  const { getToken } = useAuth();

  const postNewOrder = async (values: any): Promise<{ hasError: boolean; response: any }> => {
    try {
      const token = await getToken();
      const response = await api.post('/orders', values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return {
        hasError: false,
        response: response.data
      };
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || 'Une erreur est survenue';

      return {
        hasError: true,
        response: errorMessage
      };
    }
  };

  const getOrderById = async (orderId: string) => { // : Promise<OrderModel>
    try {
      // Get order
      const token = await getToken();
      const response = await api.get(`/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response)
      return response
    } catch (error) {
      throw error;
    }
  };

  const getUserOrders = async (userId: string) => {
    try {
      const token = await getToken();
      const response = await api.get(`/users/${userId}/orders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const transformedOrders = response.data.orders.map((order: OrderModelDB) => ({
        id: order.id,
        buyerId: order.buyerId,
        deliveryState: order.deliveryState,
        expectedDeliveryDate: order.expectedDeliveryDate,
        htPrice: order.htPrice,
        invoiceUrl: order.invoiceUrl,
        paid: order.paid,
        products: order.products,
        ttcPrice: order.ttcPrice
      }));
      return transformedOrders;
    } catch (error) {
      throw error;
    }
  };

  return { postNewOrder, getOrderById, getUserOrders };
}

export default useOrdersService;