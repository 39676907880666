import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useUser } from '@clerk/clerk-react';
import { Input } from 'components/ui/input';
import { AddressModel } from 'models/AddressModel';
import { Button } from 'components/ui/button';

interface BillingInfosCardProps {
  addresses: AddressModel[];
  handleAddAddress: () => void;
}

const BillingInfosCard: React.FC<BillingInfosCardProps> = ({ addresses, handleAddAddress }) => {
  const { register, formState: { errors } } = useForm();
  const { user } = useUser();
  const [primaryAddress, setPrimaryAddress] = useState<string>("")


  // ! TODO: How do we determine which address is the primary ? For now, take the last one.
  useEffect(() => {
    const getPrimaryAddress = async () => {
      if (user?.id) {
        if (addresses[addresses.length -1]?.name && addresses[addresses.length -1]?.street && addresses[addresses.length -1]?.zipCode && addresses[addresses.length -1]?.city) {
          let addressToDisplay = addresses[addresses.length -1].name + " - " + addresses[addresses.length -1].street + " (" +
            addresses[addresses.length -1].zipCode + ") " + addresses[addresses.length -1].city;
          setPrimaryAddress(addressToDisplay);
        }
      }
    };
    getPrimaryAddress();
  }, [addresses, user?.id]);



  return (
    <div className="bg-white rounded-lg border border-gray-400 p-6 shadow-sm">
      <h2 className="text-lg font-bold mb-4">Informations personnelles</h2>

      <div className="mb-4 grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">Nom complet</label>
          <Input
            id="fullName"
            type="text"
            defaultValue={user?.fullName ? user?.fullName : ""}
            {...register('fullName', { required: true })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            disabled={true}
          />
          {errors.fullName && <span className="text-red-600">Ce champ est requis</span>}
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Adresse Email</label>
          <Input
            id="email"
            type="email"
            defaultValue={user?.primaryEmailAddress?.emailAddress ? user?.primaryEmailAddress?.emailAddress : ""}
            {...register('email', { required: true })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            disabled={true}
          />
          {errors.email && <span className="text-red-600">Ce champ est requis</span>}
        </div>
      </div>

      <div className="mb-4 flex items-center gap-4">
        <div className="flex-1">
          <label htmlFor="address" className="block text-sm font-medium text-gray-700">Adresse</label>
          <Input
            id="address"
            type="text"
            defaultValue={addresses.length > 0 ? primaryAddress : ""}
            placeholder={addresses.length === 0 ? "Aucune adresse enregistrée" : ""}
            {...register('address')}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            disabled={true}
          />
        </div>

        {addresses.length === 0 && (
          <Button
            onClick={handleAddAddress}
            className="w-full mt-5"
          >
            Ajouter une adresse
          </Button>
        )}
      </div>

      {/* <div className="mb-4">
        <label htmlFor="cardHolder" className="block text-sm font-medium text-gray-700">Titulaire de la Carte</label>
        <input
          id="cardHolder"
          type="text"
          {...register('cardHolder', { required: true })}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
        {errors.cardHolder && <span className="text-red-600">Ce champ est requis</span>}
      </div>

      <div className="mb-4">
        <label htmlFor="cardNumber" className="block text-sm font-medium text-gray-700">Numéro de Carte</label>
        <input
          id="cardNumber"
          type="text"
          {...register('cardNumber', { required: true })}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
        {errors.cardNumber && <span className="text-red-600">Ce champ est requis</span>}
      </div>
      
      <div className="mb-4 grid grid-cols-3 gap-4">
        <div>
          <label htmlFor="expiryMonth" className="block text-sm font-medium text-gray-700">MM</label>
          <input
            id="expiryMonth"
            type="text"
            {...register('expiryMonth', { required: true })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
          {errors.expiryMonth && <span className="text-red-600">Ce champ est requis</span>}
        </div>
        <div>
          <label htmlFor="expiryYear" className="block text-sm font-medium text-gray-700">YYYY</label>
          <input
            id="expiryYear"
            type="text"
            {...register('expiryYear', { required: true })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
          {errors.expiryYear && <span className="text-red-600">Ce champ est requis</span>}
        </div>
        <div>
          <label htmlFor="cvc" className="block text-sm font-medium text-gray-700">CVC</label>
          <input
            id="cvc"
            type="text"
            {...register('cvc', { required: true })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
          {errors.cvc && <span className="text-red-600">Ce champ est requis</span>}
        </div>
      </div> */}

    </div>
  );
};

export default BillingInfosCard;