import { useUser } from '@clerk/clerk-react';
import MarketPlace from '../../pages/marketplace/MarketPlace';

const ProtectedRoute = ({ userRole, children }) => {
  const { isSignedIn, user } = useUser();

  const getUserRole = () => {
    return user.publicMetadata?.userType;
  }

  if (isSignedIn && userRole.includes(getUserRole())) {
    return children ? children : null;
  }

  return <MarketPlace />;
};

export default ProtectedRoute;
