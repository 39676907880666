import React from 'react'
import FilesSend from 'components/Globals/Forms/FilesSend'

const SendFiles: React.FC = () => {

  return (
    <div className='container p-8 bg-[#f3f4f6] rounded-sm'>
      <FilesSend />
    </div>
  )
}

export default SendFiles