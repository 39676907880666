import React from 'react';
import { ProductModel } from 'models/ProductModel';
import { useCart } from 'services/CartService';
import { CircleMinus, CirclePlus, Trash2 } from 'lucide-react';

const CartProductList: React.FC<{ checkout?: boolean }> = (props) => {
    const { addToCart, removeFromCart, getCart, updateCartItemSelected } = useCart();

    const handleAddToCart = (productId: string) => {
        addToCart(productId, 1, true);
    };

    const handleRemoveFromCart = (productId: string, quantity: number) => {
        removeFromCart(productId, quantity);
    };

    const handleSelectProduct = (product: ProductModel) => {
        updateCartItemSelected(product.productId, !product.selected)
    };

    const checkForSelectedProduct = () => {
        const cart = getCart();
        if (cart.length < 1) { 
            return <p className='text-center text-primary'>Votre panier est vide</p>
        }
        else if (cart.some(product => product.selected === true) === false) { 
            return <p className='text-center text-accent'>Veuillez sélectionner un produit</p>
        }
        else return null
    }

    return (
      <div className="overflow-y-auto" style={{ scrollbarWidth: "none", WebkitOverflowScrolling: "touch" }}>
        {checkForSelectedProduct()}
        <ul>
          {getCart().map((product, index) => (
            <React.Fragment key={index}>
              <li className={`flex gap-2 py-1`}>
                <div className='flex-1 flex items-center'>
                  {!props.checkout && <input
                    type="checkbox"
                    checked={product.selected}
                    onChange={() => handleSelectProduct(product)}
                    className="w-5 h-5 mr-2"
                    style={{ accentColor: '#006400' }}
                    disabled={!product.isAvailable}
                  />}
                  <div className={`w-16 h-16 bg-gray-200 rounded overflow-hidden ${!product.selected || !product.isAvailable ? 'opacity-50 cursor-not-allowed' : ''}`}>
                    <img src={product.image} alt={product.productName} className="w-full h-full object-cover" />
                  </div>
                  <div className="flex-1 ml-2 relative">
                    <p className={`font-bold text-16 ${!product.selected || !product.isAvailable ? 'opacity-50 cursor-not-allowed' : ''}`}>
                      {product.productName}
                    </p>
                    <p className='text-xs font-bold text-gray-400'>Conditionnement : {product.minimum} {product.unitFormat}</p>
                    <div className={`flex items-center mt-1 gap-1 ${!product.selected || !product.isAvailable ? 'opacity-50 cursor-not-allowed' : ''}`}>
                      {!props.checkout && <button
                        className="grid place-items-center"
                        onClick={() => handleRemoveFromCart(product.productId, 1)}
                        disabled={!product.isAvailable}
                      >
                        <CircleMinus />
                      </button>}
                      {props.checkout && <span>quantité : </span>}
                      <span className="font-bold">{product.quantity}</span>
                      {!props.checkout && <button
                        className="grid place-items-center"
                        onClick={() => handleAddToCart(product.productId)}
                        disabled={!product.isAvailable}
                      >
                        <CirclePlus />
                      </button>}
                    </div>
                  </div>
                </div>
                <div>
                  {product.isAvailable ?
                    <div className='flex flex-col items-end'>
                      <p className="font-bold text-md">{product.quantity && product.price && (product.price * product.quantity * product.minimum).toFixed(2)}€</p>
                      <p className='ml-1 text-xs text-gray-400 italic'>{product.price.toFixed(2)}€/{product.unitFormat}</p>
                    </div>
                  :
                    <div className='flex flex-col items-end'>
                      <p className="font-bold text-md text-red-500 opacity-100">Indisponible</p>
                      <div className="right-0 top-0 p-1 opacity-100">
                        <button
                          onClick={() => handleRemoveFromCart(product.productId, product.quantity)}
                          className="p-1 mt-5 rounded-full hover:bg-rose-200"
                        >
                          <Trash2 color="red" />
                        </button>
                      </div>
                    </div>
                  }
                </div>
              </li>
              <hr className="my-2" />
            </React.Fragment>
          ))}
        </ul>
      </div>
    );
};

export default CartProductList