import React, { useEffect } from 'react'

const FilesSend: React.FC = () => {

  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://server.fillout.com/embed/v1/";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <>
      <h1 className='text-center text-2xl text-bold'>Envoyez-nous votre mercuriale</h1>
      <div className="w-full h-[500px]" data-fillout-id="2g6McprQJMus" data-fillout-embed-type="standard" data-fillout-inherit-parameters></div>
    </>
  )
}

export default FilesSend