import React from 'react';

interface CategoryButtonProps {
  icon: React.ReactNode;
  id: string;
  name: string;
  onClick: () => void;
  selected?: string;
}

const CategoryButton: React.FC<CategoryButtonProps> = ({ icon, name, selected, id, onClick }) => {

  const handleCategoryClick = () => {
    onClick();
  }

  return (
    <div
      className={`h-32 border border-solid rounded-lg overflow-hidden flex flex-col cursor-pointer 
    ${selected === id ? 'border-green-700 bg-white' : 'border-[#EADFDF] bg-white'} 
    transition-all duration-200`}
      onClick={() => handleCategoryClick()}
    >
      <div className={`flex-grow-[1] flex justify-center items-center border-b 
    ${selected === id ? 'border-green-700 text-green-700' : 'border-[#EADFDF] text-black'}`}>
        {icon}
      </div>
      <div className={`flex-grow flex justify-center items-center 
    ${selected === id ? 'text-green-700' : 'text-black'}`}>
        <p className="text-md">{name}</p>
      </div>
    </div>
  );
}

export default CategoryButton;
