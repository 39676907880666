import React from 'react';
import { FileDown, ReceiptEuro, Truck, HandCoins } from 'lucide-react';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table"
import { OrderModelDB } from 'models/dao/OrderModelDB';


const OrderView: React.FC<{order: OrderModelDB, date: string}> = ({order, date}) => {

  return (
    <>
      <div className='flex justify-between'>
        <h1 className='text-2xl'>Commande N°{order.id.substring(order.id.length-6)}</h1>
        <a className='opacity-60 hover:opacity-100' href={order.invoiceUrl}><FileDown size={30} /></a>
      </div>

      <div className='pt-8 pb-10 flex gap-4'>
        <div className='flex-1 border-y-2 py-2'>
          <Truck size={40}/>
          <p className='mt-2 font-bold text-xl'>{date}</p> 
        </div>
        <div className='flex-1 border-y-2 py-2'>
          <ReceiptEuro size={40}/>
          <p className='mt-2 font-bold text-xl'>{order.htPrice}€ TTC</p>
        </div>
        <div className='flex-1 border-y-2 py-2'>
          <HandCoins size={40}/>
          <p className='mt-2 font-bold text-xl'>{(Number(order.ttcPrice)-Number(order.htPrice)).toFixed(2)}€ TVA</p>
        </div>
      </div>

      <h2>Liste des produits</h2>
      <Table>
        <TableHeader>
          <TableRow className='hover:bg-white'>
            <TableHead className="w-[100px]">Référence</TableHead>
            <TableHead>Nom</TableHead>
            <TableHead>Conditionnement</TableHead>
            <TableHead className="text-right">Quantité</TableHead>
            <TableHead className="text-right">Prix</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {order.products.map((product, index) => (
            <TableRow key={index}>
              <TableCell className="font-medium">{product.crmProductId}</TableCell>
              <TableCell>{product.name}</TableCell>
              <TableCell>{product.sellingUnit} {product.unitFormat}</TableCell>
              <TableCell className="text-right">{product.quantity}</TableCell>
              <TableCell className="text-right">{(product.price*product.sellingUnit*product.quantity).toFixed(2)}€</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={3} />
            <TableCell className="text-right">Total HT</TableCell>
            <TableCell className="text-right">{order.htPrice}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3} />
            <TableCell className="text-right">Total TTC</TableCell>
            <TableCell className="text-right">{order.ttcPrice}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
};

export default OrderView;
